import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import app from './modules/app';
import waitlist from './modules/waitlist';
import reserve from './modules/reserve';
import store from './modules/store';
import draw from './modules/draw';
import prize from './modules/prize';
import content from './modules/content';
import coupon from './modules/coupon';

Vue.use(Vuex);

const state = {
    debug:
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === 'localhost' ||
        window.location.href.indexOf('http://1') === 0
};

const getters = {
    serverRoot(state) {
        const localServerPort = '5353';
        const { hostname, protocol } = window.location;
        return `${protocol}//${hostname}${state.debug ? ':' + localServerPort : ''}`;
    }
};

state.is_development = state.debug || window.location.hostname === 'minipassapp.minitable.link';

export default new Vuex.Store({
    modules: {
        app,
        waitlist,
        reserve,
        store,
        draw,
        prize,
        content,
        coupon
    },
    state,
    getters,
    strict: state.debug,
    plugins: state.debug ? [createLogger()] : []
});
