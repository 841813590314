import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import ThanksPage from './views/Thanks.vue';
import WaitlistPage from './views/Waitlist.vue';
import ReservationPage from './views/Reservation.vue';
import ProfilePage from './views/Profile.vue';
import SharePage from './views/Share.vue';
import PrizePage from './views/Prize.vue';
import ContentPage from './views/Content.vue';
import CouponPage from './views/Coupon.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/review', component: ThanksPage },
        { path: '/waitlist/:id', component: WaitlistPage },
        { path: '/reserve/:id', component: ReservationPage },
        { path: '/r_profile/:id', component: ProfilePage },
        { path: '/draw/:id', component: SharePage },
        { path: '/prize/:oid/:sid', component: PrizePage },
        { path: '/content/:id', component: ContentPage },
        { path: '/coupon/:id', component: CouponPage },
        // { path: '*', redirect: '/review' }
    ]
});

router.beforeEach(async (to, from, next) => {
    store.commit('app/APP_SET_IF_LOADING', { is_loading: false });
    next();
});

export default router;
